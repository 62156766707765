"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _user = _interopRequireDefault(require("./modules/user"));
var _tags = _interopRequireDefault(require("./modules/tags"));
var _common = _interopRequireDefault(require("./modules/common"));
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    user: _user.default,
    tags: _tags.default,
    common: _common.default
  },
  getters: _getters.default
});
var _default = exports.default = store;
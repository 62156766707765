var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "avatar-uploader",
          attrs: {
            action: "/guns-cloud-file/uploadApk",
            headers: _vm.config,
            name: "file",
            "show-file-list": false,
            disabled: _vm.isDetail,
            "on-success": _vm.handleAvatarSuccess,
            "on-progress": _vm.handleLoading,
            "on-error": _vm.handleError,
            "before-upload": _vm.beforeAvatarUpload,
          },
        },
        [
          _c("el-image", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageUrl,
                expression: "imageUrl",
              },
            ],
            staticClass: "avatar",
            attrs: { src: _vm.imageUrl, fit: "cover" },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.imageUrl,
                expression: "!imageUrl",
              },
            ],
            staticClass: "el-icon-plus avatar-uploader-icon",
            staticStyle: { border: "1px dashed #7f7f7f" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "sj-container" },
    [
      _c("sj-sidebar"),
      _vm._v(" "),
      _c(
        "el-container",
        { staticClass: "sj-content", attrs: { direction: "vertical" } },
        [
          _c("sj-header"),
          _vm._v(" "),
          _c(
            "el-main",
            { staticClass: "sj-main" },
            [
              _c(
                "el-scrollbar",
                {
                  staticClass: "sj-main-scrollbar",
                  staticStyle: { height: "100%" },
                  attrs: {
                    "wrap-style": "overflow-x: hidden; overflow-y: auto;",
                  },
                },
                [
                  _c(
                    "keep-alive",
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade-transverse" } },
                        [
                          _vm.$route.meta.keepAlive
                            ? _c("router-view", { staticClass: "sj-view" })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fade-transverse" } },
                    [
                      !_vm.$route.meta.keepAlive
                        ? _c("router-view", { staticClass: "sj-view" })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
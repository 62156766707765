"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler.js"));
var _tags = _interopRequireDefault(require("./tags"));
var _changeTheme = _interopRequireDefault(require("@/layout/change-theme"));
var _screenfull = _interopRequireDefault(require("screenfull"));
var _modify_password = _interopRequireDefault(require("./modify_password"));
var _UserApi = _interopRequireDefault(require("@/api/UserApi"));
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "index",
  components: {
    tags: _tags.default,
    changeTheme: _changeTheme.default,
    ModifyPassword: _modify_password.default
  },
  data: function data() {
    return {
      // collapseFlag: false,
      storageHandler: new _StorageHandler.default(),
      // userInfo: {
      //   name: 'xxx测试员'
      // },
      isShowChangeThemeDialog: false,
      //控制更换主题弹窗是否显示
      isFullscreen: false,
      isShowModifyPassword: false,
      //控制修改密码弹窗是否显示
      appList: []
    };
  },
  created: function created() {
    var _this = this;
    var http = new _UserApi.default();
    http.appList({
      pageSize: 9999
    }).then(function (res) {
      _this.appList = res.data.records;
    });
  },
  mounted: function mounted() {},
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['selectedTags', 'userInfo', 'collapseFlag'])),
  props: [],
  methods: {
    collapseFlagChange: function collapseFlagChange() {
      this.$store.dispatch('setCollapse', !this.collapseFlag);
    },
    logout: function logout() {
      var _this2 = this;
      this.$confirm('此操作将退出系统, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.storageHandler.clearSessionStorage();
        window.location.href = "".concat(process.env.VUE_APP_SSO_HOST, "/sso/logout?clientId=").concat(process.env.VUE_APP_SSO_APP_ID);
        // this.$router.push('/login')
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消退出'
        });
      });
    },
    //关闭更换主题弹窗
    closeThemeDialog: function closeThemeDialog() {
      this.isShowChangeThemeDialog = false;
    },
    handlePersonCommand: function handlePersonCommand(command) {
      if (command == 'gerenxinxi') {
        this.$router.push('/personInfo');
      } else if (command == 'genghuanpifu') {
        this.isShowChangeThemeDialog = true;
      } else if (command == 'xiugaimima') {
        this.isShowModifyPassword = true;
      }
    },
    //关闭修改密码弹窗
    closeModifyPassword: function closeModifyPassword() {
      this.isShowModifyPassword = false;
    },
    handleScreen: function handleScreen() {
      if (!_screenfull.default.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        });
        return false;
      }
      _screenfull.default.toggle();
    },
    appJump: function appJump(val) {
      if (val.appJumpAddress) {
        if ((0, _validate.validateHttpURL)(val.appJumpAddress)) {
          window.open(val.appJumpAddress);
        } else {
          this.$message.warning('请联系管理员填写正确的应用url！');
        }
      } else {
        this.$message.warning('请联系管理员配置应用url！');
      }
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _LoginApi = _interopRequireDefault(require("@/api/LoginApi"));
var _NumberUtil = _interopRequireDefault(require("../../utils/NumberUtil.js"));
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "userlogin",
  data: function data() {
    var _this = this;
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.isvalidUsername)(value)) {
        callback(new Error("请输入正确的用户名"));
      } else {
        callback();
      }
    };
    var validateCode = function validateCode(rule, value, callback) {
      if (_this.code.value != value) {
        _this.loginForm.code = "";
        _this.refreshCode();
        callback(new Error("请输入正确的验证码"));
      } else {
        callback();
      }
    };
    return {
      loginApi: new _LoginApi.default(),
      storageHandler: new _StorageHandler.default(),
      loginForm: {
        username: "",
        password: "",
        code: "",
        redomStr: ""
      },
      checked: false,
      code: {
        src: "",
        value: "",
        len: 4,
        type: "text"
      },
      loginRules: {
        username: [/*{ required: true, trigger: "blur", validator: validateUsername }*/
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        } /*,
          {min: 6, message: "密码长度最少为6位", trigger: "blur"}*/],
        code: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }, {
          min: 4,
          max: 4,
          message: "验证码长度为4位",
          trigger: "blur"
        }, {
          required: true,
          trigger: "blur",
          validator: validateCode
        }]
      },
      passwordType: "password",
      isLoginLoading: false //控制登录按钮动画加载
    };
  },
  created: function created() {
    this.refreshCode();
  },
  methods: {
    refreshCode: function refreshCode() {
      this.loginForm.redomStr = _NumberUtil.default.randomLenNum(this.code.len, true);
      this.code.type == "text" ? this.code.value = _NumberUtil.default.randomLenNum(this.code.len) : this.code.src = "".concat(this.codeUrl, "/").concat(this.loginForm.redomStr);
      this.loginForm.code = this.code.value;
    },
    showPassword: function showPassword() {
      this.passwordType == "" ? this.passwordType = "password" : this.passwordType = "";
    },
    //点击登录
    handleLogin: function handleLogin() {
      var _this2 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          var http = new _LoginApi.default();
          var data = {
            account: _this2.loginForm.username,
            password: _this2.loginForm.password
          };
          http.getAuthCode(data).then(function (res) {
            var ssoHost = process.env.VUE_APP_SSO_HOST;
            var appSsoId = process.env.VUE_APP_SSO_APP_ID;
            window.location.href = "".concat(ssoHost, "/sso/login?clientId=").concat(appSsoId, "&code=").concat(res.data.code);
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _StorageHandler = _interopRequireDefault(require("../utils/StorageHandler"));
var _SystemConfig = _interopRequireDefault(require("../config/SystemConfig"));
var _elementUi = require("element-ui");
//默认请求响应超时时间
_axios.default.defaults.timeout = 1000 * 60;
//跨域请求，允许保存cookie
_axios.default.defaults.withCredentials = true;

//HTTPrequest拦截
_axios.default.interceptors.request.use(function (config) {
  // 设置请求格式
  //config.headers["Content-Type"] = "application/json";

  // 设置token
  var storageHandler = new _StorageHandler.default();
  config.headers["Authorization"] = storageHandler.getSessionStorage('token');
  // config.headers["Authorization"] = '1'

  // 是否需要签名
  if (_SystemConfig.default.sign) {
    var signHanler = new SignHanler();
    signHanler.handler(config);
  }
  return config;
}, function (error) {
  console.log("err" + error); // for debug
  return Promise.reject(error);
});
//HTTPresponse拦截
_axios.default.interceptors.response.use(function (data) {
  return data.data;
}, function (error) {
  console.log(error);
  if (error.response.status === 401) {
    (0, _elementUi.Message)({
      showClose: true,
      message: error.response.data.message,
      type: 'error'
    });
    var storageHandler = new _StorageHandler.default();
    storageHandler.removeSessionStorage('userInfo');
    storageHandler.removeSessionStorage('token');
    window.location.href = "".concat(process.env.VUE_APP_SSO_HOST, "/sso/login?redirectUrl=").concat(encodeURIComponent(window.location.href), "&clientId=").concat(process.env.VUE_APP_SSO_APP_ID);
    return Promise.reject(error.response.data);
  } else {
    (0, _elementUi.Message)({
      showClose: true,
      message: error.response.data.message,
      type: "error"
    });
    return Promise.reject(error.response.data.message);
  }
});
var _default = exports.default = _axios.default;
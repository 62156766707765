"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _sidebarItem = _interopRequireDefault(require("./sidebarItem"));
var _MenuApi = _interopRequireDefault(require("@/api/MenuApi"));
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler.js"));
var _SystemUtil = _interopRequireDefault(require("@/utils/SystemUtil"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "SjSidebar",
  components: {
    SidebarItem: _sidebarItem.default
  },
  data: function data() {
    return {
      menuApi: new _MenuApi.default(),
      activeIndex: '',
      storageHandler: new _StorageHandler.default(),
      menus: [],
      appId: '1202565232069730305'
      // collapseFlag:false,
    };
  },
  props: [],
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["collapseFlag"])),
  created: function created() {
    console.log(this.collapseFlag);
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化页面数据
    initData: function initData() {
      var _this = this;
      // 获取菜单数据
      this.menuApi.getLeftMenuList({
        appId: this.appId
      }).then(function (res) {
        if (!_.isEmpty(res.data)) {
          _this.menus = _SystemUtil.default.convertMenus(res.data);
          _this.$store.dispatch("setMenus", res.data);
        }
      });
      var currentTag = this.storageHandler.getSessionStorage('currentTag');
      if (currentTag) {
        this.activeIndex = currentTag.id;
      }
    }
  },
  watch: {
    $route: function $route(to) {
      var currentTag = this.storageHandler.getSessionStorage('currentTag');
      if (currentTag) {
        this.activeIndex = currentTag.id;
      }
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _UserApi = _interopRequireDefault(require("@/api/UserApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'person_info',
  data: function data() {
    return {
      activeName: 'first',
      isEdit: false,
      userData: {},
      isLoading: false,
      rules: {
        lastName: [{
          required: true,
          message: '请输入姓',
          trigger: 'blur'
        }],
        firstName: [{
          required: true,
          message: '请输入名',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请输入邮箱地址',
          trigger: 'blur'
        }, {
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: ['blur', 'change']
        }]
      },
      avatar: ''
    };
  },
  created: function created() {
    var _this = this;
    this.isLoading = true;
    var http = new _UserApi.default();
    http.getCurrentUser({}).then(function (res) {
      _this.userData = res.data;
      // this.userData.avatarUrl = 'http://188.131.253.106:8002/preview/1190640934862196738'
      _this.isLoading = false;
    });
  },
  methods: {
    //编辑
    editPersonInfo: function editPersonInfo() {
      this.isEdit = true;
    },
    //保存
    savePersonInfo: function savePersonInfo() {
      var _this2 = this;
      this.$refs['userData'].validate(function (valid, object) {
        if (valid) {
          var loading = _this2.$loading({
            lock: true,
            text: '请耐心等待.....',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          var http = new _UserApi.default();
          _this2.userData.name = _this2.userData.lastName + _this2.userData.firstName;
          http.saveCurrentUser(_this2.userData).then(function (res) {
            loading.close();
            if (res.code === 200) {
              _this2.$message.success('保存成功!');
              _this2.isEdit = false;
            } else {
              _this2.$message.error('保存失败!');
            }
          }).catch(function (e) {
            loading.close();
          });
        } else {
          var validArr = [];
          Object.keys(object).forEach(function (key) {
            validArr.push(key);
          });
          var validVal = "#" + validArr[0];
          document.querySelector(validVal).scrollIntoView(true);
        }
      });
    }
  }
};
import { render, staticRenderFns } from "./img-upload.vue?vue&type=template&id=2e99c12f&scoped=true"
import script from "./img-upload.vue?vue&type=script&lang=js"
export * from "./img-upload.vue?vue&type=script&lang=js"
import style0 from "./img-upload.vue?vue&type=style&index=0&id=2e99c12f&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e99c12f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\项目\\cloud-synchronize\\_webs\\auth-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e99c12f')) {
      api.createRecord('2e99c12f', component.options)
    } else {
      api.reload('2e99c12f', component.options)
    }
    module.hot.accept("./img-upload.vue?vue&type=template&id=2e99c12f&scoped=true", function () {
      api.rerender('2e99c12f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/img-upload/img-upload.vue"
export default component.exports
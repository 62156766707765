"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("D:\\\u9879\u76EE\\cloud-synchronize\\_webs\\auth-web\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("D:\\\u9879\u76EE\\cloud-synchronize\\_webs\\auth-web\\node_modules\\core-js\\modules\\es6.promise.js");
require("D:\\\u9879\u76EE\\cloud-synchronize\\_webs\\auth-web\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("D:\\\u9879\u76EE\\cloud-synchronize\\_webs\\auth-web\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
require("./assets/css/common.scss");
require("./assets/css/element-ui.scss");
require("./styles/theme/base.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _DictConstant = _interopRequireDefault(require("./const/DictConstant"));
var _HttpConstant = _interopRequireDefault(require("./const/HttpConstant"));
var _mixin = _interopRequireDefault(require("./mixin"));
require("./components/component");
require("./assets/iconfont/iconfont.css");
require("@/permission");
// A modern alternative to CSS resets

// global css

//更换主题css

//引入iconfont

// permission control

// set ElementUI lang to EN
_vue.default.use(_elementUi.default);
_vue.default.prototype.DictConstant = _DictConstant.default;
_vue.default.prototype.HttpConstant = _HttpConstant.default;
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-aside",
    {
      staticClass: "sj-aside",
      class: {
        "is-collapse": _vm.collapseFlag,
        "no-collapse": !_vm.collapseFlag,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "sj-logo",
          class: {
            "is-collapse": _vm.collapseFlag,
            "no-collapse": !_vm.collapseFlag,
          },
        },
        [
          _c(
            "span",
            {
              class: {
                "is-show": !_vm.collapseFlag,
                "no-show": _vm.collapseFlag,
              },
            },
            [_vm._v("统一认证管理平台")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-menu",
        {
          staticStyle: { "margin-top": "60px" },
          attrs: {
            "default-active": _vm.activeIndex,
            mode: "vertical",
            "text-color":
              _vm.storageHandler.getSessionStorage("--mainThemeColor"),
            "unique-opened": true,
            "active-text-color": "#fff",
            collapse: _vm.collapseFlag,
            "collapse-transition": "",
          },
        },
        [
          _c("sidebar-item", {
            attrs: { menus: _vm.menus, isCollapse: _vm.collapseFlag },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
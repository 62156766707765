"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SessionManage = _interopRequireDefault(require("@/api/securityAudit/SessionManage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "session_manage",
  data: function data() {
    return {
      userName: '',
      //姓名或账号
      tableLoading: false,
      appList: [],
      dataList: [],
      pageNo: 1,
      //当前页面
      pageSize: 10,
      // 当前页数
      total: 0 // 总条数
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    //获取表格数据源
    getData: function getData() {
      var _this = this;
      this.tableLoading = true;
      var data = {
        userName: this.userName,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      this.getTableListData(new _SessionManage.default(), data, 'pageList').then(function (res) {
        _this.dataList = res.dataList;
        _this.pageNo = res.pageNo;
        _this.pageSize = res.pageSize;
        _this.total = res.total;
        _this.tableLoading = false;
      });
    },
    // 当前页码修改时
    handleCurrentChange: function handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getData();
    },
    // 当前页数修改时
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    },
    //搜索
    searchData: function searchData() {
      this.pageNo = 1;
      this.getData();
    },
    //清空搜索
    clearSearch: function clearSearch() {
      this.userName = '';
    },
    //下线
    offline: function offline(ticket) {
      var _this2 = this;
      this.$confirm('确定要下线吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var loading = _this2.$loading({
          lock: true,
          text: '下线中.....',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        var http = new _SessionManage.default();
        http.forcedOffline({
          ticket: ticket
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '下线成功!'
          });
          loading.close();
          _this2.getData();
        }).catch(function (e) {
          loading.close();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消下线'
        });
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm._l(_vm.menus, function (item, index) {
        return [
          item.children.length === 0
            ? _c(
                "el-menu-item",
                {
                  key: item.id,
                  attrs: { index: item.id },
                  on: {
                    click: function ($event) {
                      return _vm.open(item)
                    },
                  },
                },
                [
                  item.icon
                    ? _c("i", { staticClass: "iconfont", class: item.icon })
                    : _c("i", { staticClass: "el-icon-location" }),
                  _vm._v(" "),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              )
            : _c(
                "el-submenu",
                { key: item.id, attrs: { index: item.id } },
                [
                  _c("template", { slot: "title" }, [
                    item.icon
                      ? _c("i", { staticClass: "iconfont", class: item.icon })
                      : _c("i", { staticClass: "el-icon-location" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: { "el-menu--display": _vm.isCollapse },
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(item.children, function (child, cindex) {
                    return [
                      child.children.length == 0
                        ? _c(
                            "el-menu-item",
                            {
                              key: child.id,
                              attrs: { index: child.id },
                              on: {
                                click: function ($event) {
                                  return _vm.open(child)
                                },
                              },
                            },
                            [
                              child.icon
                                ? _c("i", {
                                    staticClass: "iconfont",
                                    class: child.icon,
                                  })
                                : _c("i", { staticClass: "el-icon-location" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(child.name))]
                              ),
                            ]
                          )
                        : _c("sidebar-item", {
                            key: cindex,
                            attrs: {
                              menus: [child],
                              isCollapse: _vm.isCollapse,
                            },
                          }),
                    ]
                  }),
                ],
                2
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
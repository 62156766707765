"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user_login = _interopRequireDefault(require("@/views/login/user_login"));
var _code_login = _interopRequireDefault(require("./code_login"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "login",
  components: {
    UserLogin: _user_login.default,
    CodeLogin: _code_login.default
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  props: [],
  methods: {
    /**
     * @description:
     * @param {type}
     * @return:
     */
    doSome: function doSome() {}
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler"));
var _SystemConfig = _interopRequireDefault(require("@/config/SystemConfig"));
var storage = new _StorageHandler.default();
var state = {
  userInfo: storage.getStorage("userInfo")
};
var mutations = {
  SET_USER_INFO: function SET_USER_INFO(state, data) {
    storage.setStorage("userInfo", data, _SystemConfig.default.storate.sessionStorageKey);
    state.userInfo = data;
  },
  CLEAR_USER_INFO: function CLEAR_USER_INFO(state, data) {
    state.userInfo = {};
    storage.removeStorage("userInfo", _SystemConfig.default.storate.sessionStorageKey);
  }
};
var actions = {
  //保存userInfo
  setUserInfo: function setUserInfo(_ref, data) {
    var commit = _ref.commit;
    commit('SET_USER_INFO', data);
  },
  //清除userInfo
  clearUserInfo: function clearUserInfo(_ref2, data) {
    var commit = _ref2.commit;
    commit('CLEAR_USER_INFO', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var DictConstant = {
  // 状态
  STATUS: {
    "ENABLE": 1,
    "DISABLE": 2
  }
};
var _default = exports.default = DictConstant;
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SidebarItem',
  data: function data() {
    return {
      storageHandler: new _StorageHandler.default()
    };
  },
  props: {
    menus: {
      type: Array
    },
    isCollapse: {
      type: Boolean
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  methods: {
    open: function open(item) {
      var reqUrl = item.code;
      if (item.url.indexOf("http") != -1 || item.url.indexOf("https") != -1) {
        reqUrl = "/myiframe/urlPath?src=".concat(reqUrl, "&name=").concat(item.name);
      }
      this.$router.push({
        path: reqUrl,
        query: item.query
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/home',
  children: [{
    path: 'home',
    name: 'home',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/index'));
      });
    },
    meta: {
      title: '首页'
    }
  }, {
    path: 'personInfo',
    name: 'personInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/person_info'));
      });
    },
    meta: {
      title: '个人信息'
    }
  }]
}, {
  path: "/ssoApp",
  name: "ssoApp",
  component: _layout.default,
  redirect: "/ssoApp/clientManage",
  children: [{
    path: "clientManage",
    name: "clientManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/ssoApp/client_manage"));
      });
    },
    meta: {
      keepAlive: true,
      title: "客户端管理"
    }
  }],
  meta: {
    keepAlive: true,
    title: "单点应用"
  }
}, {
  path: "/securityAudit",
  name: "securityAudit",
  component: _layout.default,
  redirect: "/securityAudit/loginLog",
  children: [{
    path: "loginLog",
    name: "loginLog",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/securityAudit/login_log"));
      });
    },
    meta: {
      keepAlive: true,
      title: "登录日志"
    }
  }, {
    path: "sessionManage",
    name: "sessionManage",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/securityAudit/session_manage"));
      });
    },
    meta: {
      keepAlive: true,
      title: "会话管理"
    }
  }, {
    path: "godKeyPassword",
    name: "godKeyPassword",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/securityAudit/godKeyPassword"));
      });
    },
    meta: {
      keepAlive: true,
      title: "万能密码"
    }
  }],
  meta: {
    keepAlive: true,
    title: "安全审计"
  }
}, {
  path: "/resourceMaintain",
  name: "resourceMaintain",
  component: _layout.default,
  redirect: "/resourceMaintain/resourceMaintainIndex",
  children: [{
    path: "resourceMaintainIndex",
    name: "resourceMaintainIndex",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/resourceMaintain/resource_maintain_index"));
      });
    },
    meta: {
      keepAlive: true,
      title: "资源维护"
    }
  }],
  meta: {
    keepAlive: true,
    title: "资源维护"
  }
}, {
  path: "/routingMaintenance",
  name: "routingMaintenance",
  component: _layout.default,
  redirect: "/routingMaintenance/routingMaintenanceIndex",
  children: [{
    path: "routingMaintenanceIndex",
    name: "routingMaintenanceIndex",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/routingMaintenance/routing_maintenance_index"));
      });
    },
    meta: {
      keepAlive: true,
      title: "网关路由列表"
    }
  }],
  meta: {
    keepAlive: true,
    title: "网关路由列表"
  }
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _LoginLogApi = _interopRequireDefault(require("@/api/securityAudit/LoginLogApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "login_log",
  data: function data() {
    return {
      account: '',
      //账号
      tableLoading: false,
      appList: [],
      dataList: [],
      pageNo: 1,
      //当前页面
      pageSize: 10,
      // 当前页数
      total: 0 // 总条数
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    //获取表格数据源
    getData: function getData() {
      var _this = this;
      this.tableLoading = true;
      var data = {
        account: this.account,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      this.getTableListData(new _LoginLogApi.default(), data, 'pageList').then(function (res) {
        _this.dataList = res.dataList;
        _this.pageNo = res.pageNo;
        _this.pageSize = res.pageSize;
        _this.total = res.total;
        _this.tableLoading = false;
      });
    },
    // 当前页码修改时
    handleCurrentChange: function handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getData();
    },
    // 当前页数修改时
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    },
    //搜索
    searchData: function searchData() {
      this.pageNo = 1;
      this.getData();
    },
    //清空搜索
    clearSearch: function clearSearch() {
      this.account = '';
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler"));
var _SystemConfig = _interopRequireDefault(require("@/config/SystemConfig"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "change-theme",
  props: {
    width: {
      type: String,
      default: '60vw'
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)([])),
  data: function data() {
    return {
      isShowDialog: true,
      theme: '',
      mainColor: _SystemConfig.default.defaultColor.mainColor,
      secondColor: _SystemConfig.default.defaultColor.secondColor,
      beforMainColor: '',
      beforSecondColor: ''
    };
  },
  created: function created() {
    this.beforMainColor = document.documentElement.style.getPropertyValue('--mainThemeColor');
    this.beforSecondColor = document.documentElement.style.getPropertyValue('--secondThemeColor');
    this.mainColor = this.beforMainColor;
    this.secondColor = this.beforSecondColor;
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    //选择主题
    handleChangeTheme: function handleChangeTheme(mainThemeColor, secondThemeColor) {
      this.mainColor = mainThemeColor;
      this.secondColor = secondThemeColor;
      document.documentElement.style.setProperty('--mainThemeColor', mainThemeColor);
      document.documentElement.style.setProperty('--secondThemeColor', secondThemeColor);
    },
    handleChangeMainTheme: function handleChangeMainTheme(val) {
      document.documentElement.style.setProperty('--mainThemeColor', val);
    },
    handleChangeSecondTheme: function handleChangeSecondTheme(val) {
      document.documentElement.style.setProperty('--secondThemeColor', val);
    },
    //保存
    saveTheme: function saveTheme() {
      var storageHandler = new _StorageHandler.default();
      storageHandler.setSessionStorage('--mainThemeColor', this.mainColor);
      storageHandler.setSessionStorage('--secondThemeColor', this.secondColor);
      this.closeDialog();
    },
    //取消
    cancleDialog: function cancleDialog() {
      document.documentElement.style.setProperty('--mainThemeColor', this.beforMainColor);
      document.documentElement.style.setProperty('--secondThemeColor', this.beforSecondColor);
      this.closeDialog();
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RoutingMaintenanceIndexApi = _interopRequireDefault(require("@/api/routingMaintenance/RoutingMaintenanceIndexApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "routing_maintenance_index",
  components: {
    routingMaintenanceIndexAdd: function routingMaintenanceIndexAdd(resolve) {
      return require(['./components/routing-maintenance-index-add'], resolve);
    },
    routingMaintenanceIndexDetail: function routingMaintenanceIndexDetail(resolve) {
      return require(['./components/routing-maintenance-index-detail'], resolve);
    },
    filterDetail: function filterDetail(resolve) {
      return require(['./components/filter-detail'], resolve);
    },
    filterPlantDetail: function filterPlantDetail(resolve) {
      return require(['./components/filter-plant-detail'], resolve);
    }
  },
  data: function data() {
    return {
      searchForm: {
        routeId: '' //路由ID
      },
      listData: [],
      listLoading: false,
      formValue: {},
      isShowAddDg: false,
      isShowDetailDg: false,
      isShowFilterDetailDg: false,
      isShowFilterPlantDetailDg: false
    };
  },
  created: function created() {
    this.searchPageList();
  },
  methods: {
    //搜索列表
    searchPageList: function searchPageList() {
      var _this = this;
      this.listLoading = true;
      var data = {
        routeId: this.searchForm.routeId
      };
      var routingMaintenanceIndex = new _RoutingMaintenanceIndexApi.default();
      routingMaintenanceIndex.gatewayRoutes(data).then(function (res) {
        if (res.code == 200) {
          _this.listData = res.data;
          _this.listLoading = false;
        }
      });
    },
    //清空搜索条件
    clearSearchItem: function clearSearchItem() {
      this.searchForm.routeId = '';
    },
    //打开新增弹窗
    openAddDg: function openAddDg() {
      this.addEditDgType = 'add';
      this.isShowAddDg = true;
    },
    //关闭新增弹窗
    closeAddDg: function closeAddDg() {
      this.searchPageList();
      this.isShowAddDg = false;
    },
    //打开详情弹窗
    openDetailDg: function openDetailDg(row) {
      this.formValue = row;
      this.isShowDetailDg = true;
    },
    //关闭详情弹窗
    closeDetailDg: function closeDetailDg() {
      this.isShowDetailDg = false;
    },
    //删除
    deleteList: function deleteList(row) {
      var _this2 = this;
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var routingMaintenanceIndex = new _RoutingMaintenanceIndexApi.default();
        var data = {
          routeId: row.routeId
        };
        routingMaintenanceIndex.deleteRoute(data).then(function (res) {
          if (res.code == 200) {
            _this2.searchPageList();
            _this2.$message({
              message: '删除成功',
              type: 'success'
            });
          }
        });
      }).catch(function () {
        _this2.$notify({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //打开过滤器弹窗
    openFilterDg: function openFilterDg() {
      this.isShowFilterDetailDg = true;
    },
    //关闭过滤器弹窗
    closeFilterDg: function closeFilterDg() {
      this.isShowFilterDetailDg = false;
    },
    //打开过滤器工厂弹窗
    openFilterPlantDg: function openFilterPlantDg() {
      this.isShowFilterPlantDetailDg = true;
    },
    //关闭过滤器工厂弹窗
    closeFilterPlantDg: function closeFilterPlantDg() {
      this.isShowFilterPlantDetailDg = false;
    },
    //刷新缓存
    clearCache: function clearCache() {
      var _this3 = this;
      var routingMaintenanceIndex = new _RoutingMaintenanceIndexApi.default();
      routingMaintenanceIndex.refreshRoute({}).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success('刷新成功');
        }
      });
    }
  }
};
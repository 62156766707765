"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GodKeyPasswordApi = _interopRequireDefault(require("@/api/securityAudit/GodKeyPasswordApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'godKeyPassword',
  data: function data() {
    return {
      isShowPassword: false,
      godKeyValue: '',
      expireTime: 0,
      godKeyStatus: true
    };
  },
  created: function created() {
    this.getGodKey();
  },
  methods: {
    showPassword: function showPassword() {
      this.isShowPassword = !this.isShowPassword;
    },
    getGodKey: function getGodKey() {
      var _this = this;
      var loading = this.$loading({
        lock: true,
        text: '获取密码中，请耐心等待.....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var http = new _GodKeyPasswordApi.default();
      http.getGodKey({}).then(function (res) {
        loading.close();
        _this.godKeyValue = res.data.godKeyValue;
        _this.godKeyStatus = res.data.godKeyStatus === 'enable' ? true : false;
        _this.expireTime = res.data.expireTime.toFixed(0);
        var timer = setInterval(function () {
          if (_this.expireTime > 0) {
            _this.expireTime -= 1;
          } else {
            window.clearInterval(timer);
            _this.getGodKey();
          }
        }, 1000);
      }).catch(function (e) {
        loading.close();
      });
    },
    godKeyStatusChange: function godKeyStatusChange(val) {
      var _this2 = this;
      var loading = this.$loading({
        lock: true,
        text: '变更中，请耐心等待.....',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var http = new _GodKeyPasswordApi.default();
      http.checkedStatus({}).then(function (res) {
        loading.close();
        _this2.$message.success('变更成功!');
      }).catch(function (e) {
        loading.close();
        _this2.godKeyStatus = !_this2.godKeyStatus;
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.openResourceAdd },
            },
            [_vm._v("新增")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: { "margin-bottom": "10px" },
              on: { change: _vm.getData },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.menuFlag,
                    callback: function ($$v) {
                      _vm.menuFlag = $$v
                    },
                    expression: "menuFlag",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "N" } }, [
                    _vm._v("菜单资源"),
                  ]),
                  _vm._v(" "),
                  _c("el-radio-button", { attrs: { label: "Y" } }, [
                    _vm._v("页面资源"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: { border: "", data: _vm.dataList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "应用标识",
                  prop: "appCode",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "所属模块",
                  prop: "modularName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "资源名称",
                  prop: "name",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "资源地址",
                  prop: "url",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "是否需要登录",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.requiredLogin
                          ? _c("span", [_vm._v("是")])
                          : _vm._e(),
                        _vm._v(" "),
                        !scope.row.requiredLogin
                          ? _c("span", [_vm._v("否")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "是否需要权限验证",
                  prop: "requiredPermissionFlag",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.requiredPermission
                          ? _c("span", [_vm._v("是")])
                          : _vm._e(),
                        _vm._v(" "),
                        !scope.row.requiredPermission
                          ? _c("span", [_vm._v("否")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "272" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openResourceDetail(scope.row.url)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openResourceEdit(scope.row.url)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteResource(scope.row.url)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "sj-pagination",
            attrs: {
              "current-page": _vm.pageNo,
              "page-sizes": [10, 15, 20, 50],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              "prev-text": "上一页",
              "next-text": "下一页",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowResourceAddEdit
        ? _c("resource-addEdit", {
            attrs: { url: _vm.url },
            on: { close: _vm.closeResourceAddEdit },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowResourceDetail
        ? _c("resource-detail", {
            attrs: { url: _vm.url },
            on: { close: _vm.closeResourceDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
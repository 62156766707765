"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _StorageHandler = _interopRequireDefault(require("@/utils/StorageHandler.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "img-upload",
  data: function data() {
    return {
      storageHandler: new _StorageHandler.default(),
      loading: false,
      // imageUrl:this.imageUrl,
      uploadPercent: 0
    };
  },
  props: {
    imageUrl: {
      type: String
    },
    isDetail: {
      type: Boolean
    },
    clientIds: {
      type: String
    }
  },
  computed: {
    config: function config() {
      return {
        "Authorization": this.storageHandler.getSessionStorage('token'),
        clientId: this.clientIds
      };
    }
  },
  watch: {
    // value(curVal,oldVal){
    //   this.imageUrl = curVal
    // },
  },
  mounted: function mounted() {
    console.log('初始value值====》', this.imageUrl);
  },
  methods: {
    //上传成功后
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.loading = false;
      this.imageUrl = res.data.url + '?' + Math.random();
      // console.log('上传成功后imageUrl====》',this.imageUrl)
      this.$emit('input', this.imageUrl);
    },
    //上传之前
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isJPG) {
        this.$message.error('上传的图片只能是 JPG/JPEG/PNG 格式!');
      }
      return isJPG;
    },
    //上传过程中
    handleLoading: function handleLoading(event, file, fileList) {
      this.loading = true;
      this.uploadPercent = Number(file.percentage.toFixed(0));
    },
    //上传失败
    handleError: function handleError(file, fileList) {
      this.loading = false;
      this.$message.error('上传失败！');
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _ClientManageApi = _interopRequireDefault(require("@/api/ssoApp/ClientManageApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "client_manage",
  components: {
    ClientAddEdit: function ClientAddEdit(resolve) {
      return require(['./components/client_addEdit'], resolve);
    },
    ClientDetail: function ClientDetail(resolve) {
      return require(['./components/client_detail'], resolve);
    }
  },
  data: function data() {
    return {
      appId: '',
      //搜索的应用id
      name: '',
      //搜索的应用名称
      tableLoading: false,
      appList: [],
      dataList: [],
      pageNo: 1,
      //当前页面
      pageSize: 10,
      // 当前页数
      total: 0,
      // 总条数
      isShowClientAddEdit: false,
      clientId: '',
      isShowClientDetail: false
    };
  },
  created: function created() {
    var _this = this;
    this.tableLoading = true;
    var http = new _ClientManageApi.default();
    http.getAppSelectList({}).then(function (res) {
      _this.appList = res.data;
      _this.getData();
    });
  },
  methods: {
    //获取表格数据源
    getData: function getData() {
      var _this2 = this;
      this.tableLoading = true;
      var data = {
        appId: this.appId,
        name: this.name,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      this.getTableListData(new _ClientManageApi.default(), data, 'pageList').then(function (res) {
        _this2.dataList = res.dataList;
        _this2.pageNo = res.pageNo;
        _this2.pageSize = res.pageSize;
        _this2.total = res.total;
        _this2.tableLoading = false;
      });
    },
    // 当前页码修改时
    handleCurrentChange: function handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getData();
    },
    // 当前页数修改时
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    },
    //搜索
    searchData: function searchData() {
      this.pageNo = 1;
      this.getData();
    },
    //清空搜索
    clearSearch: function clearSearch() {
      this.appId = '';
      this.name = '';
    },
    //打开新增弹窗
    openClientAdd: function openClientAdd() {
      this.isShowClientAddEdit = true;
    },
    //编辑
    edit: function edit(clientId) {
      this.clientId = clientId;
      this.isShowClientAddEdit = true;
    },
    //关闭新增编辑弹窗
    closeClientAddEdit: function closeClientAddEdit() {
      this.clientId = '';
      this.isShowClientAddEdit = false;
      this.getData();
    },
    //删除
    deleteClient: function deleteClient(clientId) {
      var _this3 = this;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var loading = _this3.$loading({
          lock: true,
          text: '删除中.....',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        var http = new _ClientManageApi.default();
        http.delete({
          clientId: clientId
        }).then(function (res) {
          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });
          loading.close();
          _this3.getData();
        }).catch(function (e) {
          loading.close();
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //详情
    detailClient: function detailClient(id) {
      this.clientId = id;
      this.isShowClientDetail = true;
    },
    //关闭详情弹窗
    closeClientDetail: function closeClientDetail() {
      this.clientId = '';
      this.isShowClientDetail = false;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            { attrs: { inline: "" } },
            [
              _c(
                "el-form-item",
                { staticClass: "mgb-0", attrs: { label: "应用列表:" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.appId,
                        callback: function ($$v) {
                          _vm.appId = $$v
                        },
                        expression: "appId",
                      },
                    },
                    _vm._l(_vm.appList, function (item) {
                      return _c("el-option", {
                        key: item.appId,
                        attrs: { label: item.appName, value: item.appId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mgb-0", attrs: { label: "名称:" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "mgb-0" },
                [
                  _c("el-button", {
                    attrs: { type: "primary", icon: "el-icon-search" },
                    on: { click: _vm.searchData },
                  }),
                  _vm._v(" "),
                  _c("el-button", {
                    attrs: { type: "success", icon: "el-icon-refresh" },
                    on: { click: _vm.clearSearch },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.openClientAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: { border: "", data: _vm.dataList },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "center",
                  width: "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "客户端Id",
                  prop: "clientId",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "名称",
                  prop: "name",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "应用名称",
                  prop: "appName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "设备类型",
                  prop: "clientTypeName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "token接收地址",
                  prop: "ssoUrl",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "登录类型",
                  prop: "loginTypeName",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.detailClient(scope.row.clientId)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row.clientId)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteClient(scope.row.clientId)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "sj-pagination",
            attrs: {
              "current-page": _vm.pageNo,
              "page-sizes": [10, 15, 20, 50],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              "prev-text": "上一页",
              "next-text": "下一页",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowClientAddEdit
        ? _c("client-addEdit", {
            attrs: { clientId: _vm.clientId },
            on: { close: _vm.closeClientAddEdit },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowClientDetail
        ? _c("client-detail", {
            attrs: { clientId: _vm.clientId },
            on: { close: _vm.closeClientDetail },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
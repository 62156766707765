"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var MSGINIT = "发送验证码",
  MSGERROR = "验证码发送失败",
  MSGSCUCCESS = "${time}秒后重发",
  MSGTIME = 60;
var _default = exports.default = {
  name: "CodeLogin",
  data: function data() {
    var validateCode = function validateCode(rule, value, callback) {
      if (value.length != 4) {
        callback(new Error("请输入4位数的验证码"));
      } else {
        callback();
      }
    };
    return {
      msgText: MSGINIT,
      msgTime: MSGTIME,
      msgKey: false,
      loginForm: {
        phone: "17547400800",
        code: ""
      },
      loginRules: {
        phone: [{
          required: true,
          trigger: "blur"
        }],
        code: [{
          required: true,
          trigger: "blur",
          validator: validateCode
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  props: [],
  methods: {
    handleSend: function handleSend() {
      var _this = this;
      if (this.msgKey) return;
      this.msgText = MSGSCUCCESS.replace("${time}", this.msgTime);
      this.msgKey = true;
      var time = setInterval(function () {
        _this.msgTime--;
        _this.msgText = MSGSCUCCESS.replace("${time}", _this.msgTime);
        if (_this.msgTime == 0) {
          _this.msgTime = MSGTIME;
          _this.msgText = MSGINIT;
          _this.msgKey = false;
          clearInterval(time);
        }
      }, 1000);
    },
    handleLogin: function handleLogin() {
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {}
      });
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var HttpConstant = {
  // 请求content-type类型
  "FORM_DATA_REQ": 'application/x-www-form-urlencoded',
  "APPLICATION_JSON_REQ": 'application/json',
  "MUL_FORM_REQ": "multipart/form-data",
  // 响应状态
  "SUCCESS_CODE": 200,
  // 响应消息
  "ADD_SUCCESS_MSG": "添加成功",
  "ADD_FAILD_MSG": "添加失败",
  "UPDATE_SUCCESS_MSG": "更新成功",
  "UPDATE_FAILD_MSG": "更新失败",
  "DELETE_SUCCESS_MSG": "删除成功",
  "DELETE_FAILD_MSG": "删除失败"
};
var _default = exports.default = HttpConstant;
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _objectSpread2 = _interopRequireDefault(require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'tags',
  data: function data() {
    return {
      visible: false,
      lock: false,
      currTag: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['homeTag', 'selectedTags', 'currentTag'])), {}, {
    nowTagValue: function nowTagValue() {
      return this.$route.path;
    }
  }),
  methods: {
    init: function init() {
      var _this = this;
      this.refsTag = this.$refs.tagsPageOpened;
      setTimeout(function () {
        _this.refsTag.forEach(function (item, index) {
          if (_this.currentTag.value === item.attributes.name.value) {
            var tag = _this.refsTag[index];
            _this.moveToView(tag);
          }
        });
      }, 1);
    },
    hadelMousewheel: function hadelMousewheel(e) {
      var step = 0.8 * 90; //一个tag长度
      var boundarystart = 0,
        boundaryend = this.$refs.tagsList.offsetWidth - this.$refs.tagBox.offsetWidth + 100;
      // Y>0向左滑动
      if (e.deltaY > 0 && this.tagBodyLeft >= -boundaryend) {
        this.tagBodyLeft = this.tagBodyLeft - step;
        // Y<0向右滑动
      } else if (e.deltaY < 0 && this.tagBodyLeft < boundarystart) {
        this.tagBodyLeft = this.tagBodyLeft + step;
      }
    },
    closeOthersTags: function closeOthersTags() {
      this.$store.commit('DEL_OTHER_TAG');
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    },
    closeAllTags: function closeAllTags() {
      this.$store.commit('DEL_ALL_TAG');
      this.$router.push({
        path: this.homeTag.value,
        query: this.homeTag.query
      });
    },
    moveToView: function moveToView(tag) {
      if (tag.offsetLeft < -this.tagBodyLeft) {
        // 标签在可视区域左侧
        this.tagBodyLeft = -tag.offsetLeft + 10;
      } else if (tag.offsetLeft + 10 > -this.tagBodyLeft && tag.offsetLeft + tag.offsetWidth < -this.tagBodyLeft + this.$refs.tagBox.offsetWidth) {
        // 标签在可视区域
      } else {
        // 标签在可视区域右侧
        this.tagBodyLeft = -(tag.offsetLeft - (this.$refs.tagBox.offsetWidth - 100 - tag.offsetWidth) + 20);
      }
    },
    openUrl: function openUrl(item) {
      this.$router.push({
        path: item.value,
        query: item.query
      });
    },
    eachTag: function eachTag(tag) {
      for (var key in this.selectedTags) {
        if (this.selectedTags[key].value == tag.value) {
          return key;
        }
      }
      return -1;
    },
    closeTag: function closeTag(item) {
      var key = this.eachTag(item);
      var tag;
      this.$store.commit('DEL_TAG', item);
      if (item.value == this.currentTag.value) {
        tag = this.selectedTags[key == 0 ? key : key - 1];
        this.openUrl(tag);
      }
    }
  },
  watch: {
    $route: function $route(to) {
      this.init();
    },
    tagBodyLeft: function tagBodyLeft(value) {
      this.$refs.tagsList.style.left = value + 'px';
    }
  }
};
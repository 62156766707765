"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.common.token;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  homeTag: function homeTag(state) {
    return state.tags.homeTag;
  },
  selectedTags: function selectedTags(state) {
    return state.tags.selectedTags;
  },
  currentTag: function currentTag(state) {
    return state.tags.currentTag;
  },
  menus: function menus(state) {
    return state.common.menus;
  },
  collapseFlag: function collapseFlag(state) {
    return state.common.collapseFlag;
  }
};
var _default = exports.default = getters;
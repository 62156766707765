"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ResourceMaintainIndexApi = _interopRequireDefault(require("@/api/resourceMaintain/ResourceMaintainIndexApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "resource_maintain_index",
  components: {
    ResourceAddEdit: function ResourceAddEdit(resolve) {
      return require(['./components/resourceAddEdit'], resolve);
    },
    ResourceDetail: function ResourceDetail(resolve) {
      return require(['./components/resourceDetail'], resolve);
    }
  },
  data: function data() {
    return {
      appName: '',
      //搜索的应用名称
      menuFlag: 'N',
      tableLoading: false,
      dataList: [],
      pageNo: 1,
      //当前页面
      pageSize: 10,
      // 当前页数
      total: 0,
      // 总条数
      isShowResourceAddEdit: false,
      url: '',
      isShowResourceDetail: false
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    //获取表格数据源
    getData: function getData() {
      var _this = this;
      this.tableLoading = true;
      var data = {
        menuFlag: this.menuFlag,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      this.getTableListData(new _ResourceMaintainIndexApi.default(), data, 'getResCacheList').then(function (res) {
        _this.dataList = res.dataList;
        _this.pageNo = res.pageNo;
        _this.pageSize = res.pageSize;
        _this.total = res.total;
        _this.tableLoading = false;
      });
    },
    // 当前页码修改时
    handleCurrentChange: function handleCurrentChange(pageNo) {
      this.pageNo = pageNo;
      this.getData();
    },
    // 当前页数修改时
    handleSizeChange: function handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getData();
    },
    //搜索
    searchData: function searchData() {
      this.pageNo = 1;
      this.getData();
    },
    //打开新增弹窗
    openResourceAdd: function openResourceAdd() {
      this.isShowResourceAddEdit = true;
    },
    //打开编辑弹窗
    openResourceEdit: function openResourceEdit(url) {
      this.url = url;
      this.isShowResourceAddEdit = true;
    },
    //关闭新增编辑弹窗
    closeResourceAddEdit: function closeResourceAddEdit() {
      this.url = '';
      this.isShowResourceAddEdit = false;
      this.getData();
    },
    //打开详情弹窗
    openResourceDetail: function openResourceDetail(url) {
      this.url = url;
      this.isShowResourceDetail = true;
    },
    //关闭详情弹窗
    closeResourceDetail: function closeResourceDetail() {
      this.url = '';
      this.isShowResourceDetail = false;
    },
    //删除
    deleteResource: function deleteResource(cacheKey) {
      var _this2 = this;
      this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var loading = _this2.$loading({
          lock: true,
          text: '删除中，请耐心等待.....',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        var http = new _ResourceMaintainIndexApi.default();
        http.removeResCache({
          cacheKey: cacheKey
        }).then(function (res) {
          loading.close();
          _this2.$message.success('删除成功!');
          _this2.getData();
        }).catch(function (e) {
          loading.close();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};
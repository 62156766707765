"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _UserApi = _interopRequireDefault(require("@/api/UserApi"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'modify_password',
  props: {
    width: {
      type: String,
      default: '60vw'
    }
  },
  data: function data() {
    return {
      title: '修改密码',
      isShowVisible: true,
      rules: {
        oldPassword: [{
          required: true,
          message: '请填写旧密码',
          tirgger: 'blur'
        }],
        newPassword: [{
          required: true,
          message: '请填写新密码',
          tirgger: 'blur'
        }, {
          min: 6,
          message: "密码长度最少为6位",
          trigger: "blur"
        }],
        repeatPassword: [{
          required: true,
          message: '请确认密码',
          tirgger: 'blur'
        }, {
          min: 6,
          message: "密码长度最少为6位",
          trigger: "blur"
        }]
      },
      passwordData: {}
    };
  },
  methods: {
    savePassword: function savePassword() {
      var _this = this;
      this.$refs['passwordData'].validate(function (valid, object) {
        if (valid) {
          if (_this.passwordData.newPassword === _this.passwordData.oldPassword) {
            _this.$message.error('新老密码不能一致!');
          } else {
            if (_this.passwordData.newPassword === _this.passwordData.repeatPassword) {
              var loading = _this.$loading({
                lock: true,
                text: '请耐心等待.....',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              var http = new _UserApi.default();
              http.updatePassword(_this.passwordData).then(function (res) {
                loading.close();
                if (res.code === 200) {
                  _this.$message.success('修改成功!');
                  _this.$emit('close');
                } else {
                  _this.$message.error('修改失败!');
                }
              }).catch(function (e) {
                loading.close();
              });
            } else {
              _this.$message.error('两次输入的新密码必须一致!');
            }
          }
        } else {
          var validArr = [];
          Object.keys(object).forEach(function (key) {
            validArr.push(key);
          });
          var validVal = "#" + validArr[0];
          document.querySelector(validVal).scrollIntoView(true);
        }
      });
    }
  }
};
"use strict";

var _interopRequireDefault = require("D:/\u9879\u76EE/cloud-synchronize/_webs/auth-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("./header/index"));
var _index2 = _interopRequireDefault(require("./sidebar/index"));
var _StorageHandler = _interopRequireDefault(require("../utils/StorageHandler"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    SjHeader: _index.default,
    SjSidebar: _index2.default
  },
  name: "index",
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  props: [],
  methods: {}
};